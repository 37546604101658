import Axios from "axios";

const axios = Axios.create({
  // baseURL: "http://localhost:8000/api",
  // baseURL: "http://3dsmart.r226.eu/api",
  baseURL: "https://oferta.3dsmart.pl/api",
  withCredentials: true,
  crossDomain: true,
  headers: {
    "Content-Type": "application/json",
    "Accept": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "*",
    "Access-Control-Allow-Credentials": "true",
  },
});

export default axios;
