import React from "react";

const HomeCard = ({ text, icon, target, setPage, header }) => {
  return (
    <div
      onClick={() => {
        setPage(target);
      }}
      className=" cursor-pointer overflow-hidden min-h-[350px] w-[450px] text-center bg-white rounded shadow-md text-slate-500 shadow-slate-200"
    >
      {/* <!-- Icon --> */}
      <div className="flex justify-items-center align-middle text-7xl  pt-8 pb-0">
        {icon}
      </div>

      {/* <!-- Body--> */}
      <div className="p-6">
        <h3 className="mb-4 text-xl font-medium text-slate-700">{header}</h3>
        <p className="home-card-p">{text}</p>
      </div>
    </div>
  );
};

export default HomeCard;
