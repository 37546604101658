import React, { useEffect, useState, useRef } from "react";
import { useAuth } from "../context/AuthContext";
import axios from "../axios";
import { NavLink } from "react-router-dom";

const History = () => {
  const { csrfToken, editId, setEditId } = useAuth();
  const [loading, setLoading] = useState(true);
  const [publicHistory, setPublicHistory] = useState([]);
  const [privateHistory, setPrivateHistory] = useState([]);
  const [tabSelected, setTabSelected] = useState(false);
  const [owners, setOwners] = useState([]);

  useEffect(() => {
    loadData();
  }, []);

  function reformatDate(dateString) {
    const date = new Date(dateString);
    
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    
    
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    
    const reformattedDate = `${day}-${month}-${year} ${hours}:${minutes}`;
    
    return reformattedDate;
  }

  const handleDuplicateClick = async (duplicatedId) => {
    console.log("handleDuplicateClick");
    setLoading(true);
    const body = {
      type: "duplicate",
      id: duplicatedId,
    };
    await csrfToken();
    try {
      const resp = await axios.post("/oferta/read", body);
      loadData();
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteClick = async (deleteId) => {
    console.log("handleDeleteClick");
    setLoading(true);
    const body = {
      type: "delete",
      id: deleteId,
    };
    await csrfToken();
    try {
      const resp = await axios.post("/oferta/read", body);
      loadData();
    } catch (error) {
      console.log(error);
    }
  };

  const loadData = async () => {
    console.log("handleSaveData");
    setLoading(true);
    const body = {
      type: "history",
      id: -1,
    };
    await csrfToken();
    try {
      const resp = await axios.post("/oferta/read", body);
      setPublicHistory(resp.data.data.public);
      setPrivateHistory(resp.data.data.private);
      setOwners(resp.data.data.users);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const tabSelectedCss =
    "inline-flex items-center justify-center w-full h-12 gap-2 px-6 -mb-px text-sm font-medium tracking-wide transition duration-300 border-b-2 rounded-t focus-visible:outline-none whitespace-nowrap border-emerald-500 hover:border-emerald-600 focus:border-emerald-700 text-emerald-500 hover:text-emerald-600 focus:text-emerald-700 hover:bg-emerald-50 focus:bg-emerald-50 disabled:cursor-not-allowed disabled:border-slate-500 stroke-emerald-500 hover:stroke-emerald-600 focus:stroke-emerald-700";
  const tabCss =
    "inline-flex items-center justify-center w-full h-12 gap-2 px-6 -mb-px text-sm font-medium tracking-wide transition duration-300 border-b-2 border-transparent rounded-t focus-visible:outline-none justify-self-center hover:border-emerald-500 focus:border-emerald-600 whitespace-nowrap text-slate-700 stroke-slate-700 hover:bg-emerald-50 hover:text-emerald-500 focus:stroke-emerald-600 focus:bg-emerald-50 focus:text-emerald-600 hover:stroke-emerald-600 disabled:cursor-not-allowed disabled:text-slate-500";
  return (
    <div>
      <div className="p-8 text-center text-5xl font-bold text-gray-600">
        Historia ofert
      </div>

      <div className="flex items-center justify-center w-full p-8 bg-white rounded-xl sm:p-16 ">
        {!loading ? (
          <section class="max-w-full" aria-multiselectable="false">
            <ul
              class="flex items-center border-b border-slate-200"
              role="tablist"
            >
              <li class="flex-1" role="presentation ">
                <button
                  class={tabSelected === false ? tabSelectedCss : tabCss}
                  id="tab-label-1a"
                  role="tab"
                  aria-setsize="3"
                  aria-posinset="1"
                  tabindex="0"
                  aria-controls="tab-panel-1a"
                  aria-selected="true"
                  onClick={() => {
                    setTabSelected(false);
                  }}
                >
                  <span>Oferty prywatne</span>
                </button>
              </li>
              <li class="flex-1" role="presentation ">
                <button
                  class={tabSelected === true ? tabSelectedCss : tabCss}
                  id="tab-label-2a"
                  role="tab"
                  aria-setsize="3"
                  aria-posinset="2"
                  tabindex="-1"
                  aria-controls="tab-panel-2a"
                  aria-selected="false"
                  onClick={() => {
                    setTabSelected(true);
                  }}
                >
                  <span>Oferty publiczne</span>
                </button>
              </li>
            </ul>
            <div>
              <div
                className={
                  tabSelected === true
                    ? "hidden w-100 px-6 py-4"
                    : "px-6 w-100 py-4"
                }
                id="tab-panel-1a"
                aria-hidden="false"
                role="tabpanel"
                aria-labelledby="tab-label-1a"
                tabindex="-1"
              >
                {privateHistory.length === 0 ? (
                  <h1 className="w-100">
                    Brak ofert prywatnych do wyświetlenia
                    <div className="min-w-[1000px] w-full"></div>
                  </h1>
                ) : (
                  <>
                    <div class="w-full overflow-x-auto">
                      <table
                        class="w-full text-left border border-separate rounded border-slate-200"
                        cellspacing="0"
                      >
                        <tbody>
                          <tr>
                            <th
                              scope="col"
                              class="h-12 px-6 text-sm font-medium border-l first:border-l-0 stroke-slate-700 text-slate-700 bg-slate-100"
                            >
                              ID
                            </th>
                            <th
                              scope="col"
                              class="h-12 px-6 text-sm font-medium border-l first:border-l-0 stroke-slate-700 text-slate-700 bg-slate-100"
                            >
                              Data utworzenia
                            </th>
                            <th
                              scope="col"
                              class="h-12 px-6 text-sm font-medium border-l first:border-l-0 stroke-slate-700 text-slate-700 bg-slate-100"
                            >
                              Twórca
                            </th>
                            <th
                              scope="col"
                              class="h-12 px-6 text-sm font-medium border-l first:border-l-0 stroke-slate-700 text-slate-700 bg-slate-100"
                            >
                              Opis oferty
                            </th>
                            <th
                              scope="col"
                              class="h-12 w-32 px-6 text-sm font-medium border-l first:border-l-0 stroke-slate-700 text-slate-700 bg-slate-100"
                            >
                              
                            </th>
                          </tr>
                          {privateHistory.map((item) => {
                            return (
                              <tr class="odd:bg-slate-50">
                                <td class="h-12 px-6 text-sm transition duration-300 border-t border-l first:border-l-0 border-slate-200 stroke-slate-500 text-slate-500 ">
                                  {item.id}
                                </td>
                                <td class="h-12 px-6 text-sm transition duration-300 border-t border-l first:border-l-0 border-slate-200 stroke-slate-500 text-slate-500 ">
                                  {reformatDate(item.created_at)}
                                </td>
                                <td class="h-12 px-6 text-sm transition duration-300 border-t border-l first:border-l-0 border-slate-200 stroke-slate-500 text-slate-500 ">
                                  {owners.length > 0
                                    ? owners.map((owner) => {
                                        if (owner.id == item.owner) {
                                          return <span>{owner.name}</span>;
                                        }
                                      })
                                    : "---"}
                                </td>
                                <td class="h-12 px-6 text-sm transition duration-300 border-t border-l first:border-l-0 border-slate-200 stroke-slate-500 text-slate-500 ">
                                  {item.name}
                                </td>
                                <td class="h-12 px-6 text-sm transition duration-300 border-t border-l first:border-l-0 border-slate-200 stroke-slate-500 text-slate-500 ">
                                  <NavLink
                                    to="/edit"
                                    onClick={() => {
                                      setEditId(item.id);
                                    }}
                                    className="table-item text-green-500 font-bold"
                                  >
                                    <span>Otwórz</span>
                                  </NavLink>
                                  <NavLink
                                    to="  "
                                    onClick={() => {
                                      handleDuplicateClick(item.id)
                                    }}
                                    className="table-item text-blue-500"
                                  >
                                    <span>Duplikuj</span>
                                  </NavLink>
                                  <NavLink
                                    to=" "
                                    onClick={() => {
                                      handleDeleteClick(item.id)
                                    }}
                                    className="table-item text-red-700 font-bold"
                                  >
                                    <span>Usuń</span>
                                  </NavLink>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    <div className="min-w-[1000px] w-full"></div>
                  </>
                )}
              </div>
              <div
                className={
                  tabSelected === false ? "hidden px-6 py-4" : "px-6 py-4"
                }
                id="tab-panel-2a"
                aria-hidden="true"
                role="tabpanel"
                aria-labelledby="tab-label-2a"
                tabindex="-1"
              >
                <p>
                  {publicHistory.length === 0 ? (
                    <h1 className="w-100">
                      Brak ofert publicznych do wyświetlenia
                      <div className="min-w-[1000px] w-full"></div>
                    </h1>
                  ) : (
                    <>
                      <table
                        class="w-full text-left border border-separate rounded border-slate-200"
                        cellspacing="0"
                      >
                        <tbody>
                          <tr>
                            <th
                              scope="col"
                              class="h-12 px-6 text-sm font-medium border-l first:border-l-0 stroke-slate-700 text-slate-700 bg-slate-100"
                            >
                              ID
                            </th>
                            <th
                              scope="col"
                              class="h-12 px-6 text-sm font-medium border-l first:border-l-0 stroke-slate-700 text-slate-700 bg-slate-100"
                            >
                              Data utworzenia
                            </th>
                            <th
                              scope="col"
                              class="h-12 px-6 text-sm font-medium border-l first:border-l-0 stroke-slate-700 text-slate-700 bg-slate-100"
                            >
                              Twórca
                            </th>
                            <th
                              scope="col"
                              class="h-12 px-6 text-sm font-medium border-l first:border-l-0 stroke-slate-700 text-slate-700 bg-slate-100"
                            >
                              Opis oferty
                            </th>
                            <th
                              scope="col"
                              class="h-12 px-6 text-sm font-medium border-l first:border-l-0 stroke-slate-700 text-slate-700 bg-slate-100"
                            >
                              ...
                            </th>
                          </tr>
                          {publicHistory.map((item) => {
                            return (
                              <tr class="odd:bg-slate-50">
                                <td class="h-12 px-6 text-sm transition duration-300 border-t border-l first:border-l-0 border-slate-200 stroke-slate-500 text-slate-500 ">
                                  {item.id}
                                </td>
                                <td class="h-12 px-6 text-sm transition duration-300 border-t border-l first:border-l-0 border-slate-200 stroke-slate-500 text-slate-500 ">
                                  {reformatDate(item.created_at)}
                                </td>
                                <td class="h-12 px-6 text-sm transition duration-300 border-t border-l first:border-l-0 border-slate-200 stroke-slate-500 text-slate-500 ">
                                  {owners.length > 0
                                    ? owners.map((owner) => {
                                        if (owner.id == item.owner) {
                                          return <span>{owner.name}</span>;
                                        }
                                      })
                                    : "---"}
                                </td>
                                <td class="h-12 px-6 text-sm transition duration-300 border-t border-l first:border-l-0 border-slate-200 stroke-slate-500 text-slate-500 ">
                                  {item.name}
                                </td>
                                <td class="h-12 px-6 text-sm transition duration-300 border-t border-l first:border-l-0 border-slate-200 stroke-slate-500 text-slate-500 ">
                                  <NavLink
                                    to="/edit"
                                    onClick={() => {
                                      setEditId(item.id);
                                    }}
                                    className="table-item"
                                  >
                                    <span>Otwórz</span>
                                  </NavLink>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                      <div className="min-w-[1000px] w-full"></div>
                    </>
                  )}
                </p>
              </div>
            </div>
          </section>
        ) : (
          <div className="text-5xl text-gray-300 font-bold text-center p-8">
            Ładowanie danych
          </div>
        )}
      </div>
    </div>
  );
};

export default History;
