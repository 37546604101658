import { useState } from "react";
import "./App.css";

import Header from "./components/Header";
import Home from "./components/Home";
import Footer from "./components/Footer";
import History from "./components/History";
import Add from "./components/Add";

function App() {
  const [page, setPage] = useState("home");
  const [hide, setHide] = useState(false);

  return (
    <>
      <Home page={page} setPage={setPage}/>
    </>
  );
}

export default App;
