import React, { useEffect, useState, useRef } from "react";
import { useAuth } from "../context/AuthContext";
import axios from "../axios";
import { NavLink } from "react-router-dom";
import UserAddModal from "./UserAddModal";
import UserEditModal from "./UserEditModal";

const Users = () => {
  const { csrfToken, editId, setEditId } = useAuth();
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [editUser, setEditUser] = useState({});
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  useEffect(() => {
    loadData();
  }, []);

  function reformatDate(dateString) {
    const date = new Date(dateString);

    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();

    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    const reformattedDate = `${day}-${month}-${year} ${hours}:${minutes}`;

    return reformattedDate;
  }

  const handleStoreNewUser = async (name, email, password) => {
    setLoading(true);
    const body = {
      type: "create",
      id: "-1",
      data: {
        name: name,
        email: email,
        password: password,
      },
    };
    await csrfToken();
    try {
      const resp = await axios.post("/admin/users/add", body);
      console.log(resp);
      setShowAddModal(false);
      setLoading(false);
      loadData();
    } catch (error) {
      console.log(error);
    }
  };
  const handleStoreEditUser = async (id, name, email, password) => {
    setLoading(true);
    const body = {
      type: "edit",
      id: id + "",
      data: {
        name: name,
        email: email,
        password: password,
        id: id + "",
      },
    };
    console.log(body);
    await csrfToken();
    try {
      const resp = await axios.post("/admin/users/edit", body);
      console.log(resp);
      setShowEditModal(false);
      setLoading(false);
      loadData();
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteUser = async (id) => {
    setLoading(true);
    const body = {
      type: "create",
      id: id + "",
      data: {
        id: id + "",
      },
    };
    await csrfToken();
    try {
      const resp = await axios.post("/admin/users/delete", body);
      console.log(resp);
      setLoading(false);
      loadData();
    } catch (error) {
      console.log(error);
    }
  };

  const loadData = async () => {
    console.log("handleSaveData");
    setLoading(true);
    const body = {
      type: "read",
      id: "-1",
      data: "123",
    };
    await csrfToken();
    try {
      const resp = await axios.get("/admin/users", body);
      console.log(resp);
      setUsers(resp.data.users);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const tabSelectedCss =
    "inline-flex items-center justify-center w-full h-12 gap-2 px-6 -mb-px text-sm font-medium tracking-wide transition duration-300 border-b-2 rounded-t focus-visible:outline-none whitespace-nowrap border-emerald-500 hover:border-emerald-600 focus:border-emerald-700 text-emerald-500 hover:text-emerald-600 focus:text-emerald-700 hover:bg-emerald-50 focus:bg-emerald-50 disabled:cursor-not-allowed disabled:border-slate-500 stroke-emerald-500 hover:stroke-emerald-600 focus:stroke-emerald-700";
  const tabCss =
    "inline-flex items-center justify-center w-full h-12 gap-2 px-6 -mb-px text-sm font-medium tracking-wide transition duration-300 border-b-2 border-transparent rounded-t focus-visible:outline-none justify-self-center hover:border-emerald-500 focus:border-emerald-600 whitespace-nowrap text-slate-700 stroke-slate-700 hover:bg-emerald-50 hover:text-emerald-500 focus:stroke-emerald-600 focus:bg-emerald-50 focus:text-emerald-600 hover:stroke-emerald-600 disabled:cursor-not-allowed disabled:text-slate-500";
  return (
    <>
      <div>
        <div className="p-8 text-center text-5xl font-bold text-gray-600">
          Użytkownicy
        </div>
        <div className="p-4 text-center text-sm font-bold text-gray-300">
          ver 1.2.1
        </div>

        <div className="flex* items-center* justify-center* w-full p-8 bg-white rounded-xl sm:p-16 ">
          {!loading ? (
            <>
              <div className="w-full py-2">
                <button
                  onClick={() => {
                    setShowAddModal(true);
                  }}
                  class="inline-flex items-center justify-center h-12 gap-2 px-6 text-sm font-medium tracking-wide text-white transition duration-300 rounded focus-visible:outline-none whitespace-nowrap bg-emerald-500 hover:bg-emerald-600 focus:bg-emerald-700 disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none"
                >
                  <span>Dodaj użytkownika</span>
                </button>
              </div>
              {users.length === 0 ? (
                <h1 className="w-100">
                  Brak użytkowników do wyświetlenia
                  <div className="min-w-[1000px] w-full"></div>
                </h1>
              ) : (
                <>
                  <div class="">
                    <table
                      class="w-full text-left border border-separate rounded border-slate-200"
                      cellspacing="0"
                    >
                      <tbody>
                        <tr>
                          <th
                            scope="col"
                            class="h-12 px-6 text-sm font-medium border-l first:border-l-0 stroke-slate-700 text-slate-700 bg-slate-100"
                          >
                            ID
                          </th>
                          <th
                            scope="col"
                            class="h-12 px-6 text-sm font-medium border-l first:border-l-0 stroke-slate-700 text-slate-700 bg-slate-100"
                          >
                            Nazwa
                          </th>
                          <th
                            scope="col"
                            class="h-12 px-6 text-sm font-medium border-l first:border-l-0 stroke-slate-700 text-slate-700 bg-slate-100"
                          >
                            Email
                          </th>

                          <th
                            scope="col"
                            class="h-12 w-32 px-6 text-sm font-medium border-l first:border-l-0 stroke-slate-700 text-slate-700 bg-slate-100"
                          ></th>
                        </tr>
                        {users.map((user) => {
                          return (
                            <tr class="odd:bg-slate-50">
                              <td class="h-12 px-6 text-sm transition duration-300 border-t border-l first:border-l-0 border-slate-200 stroke-slate-500 text-slate-500 ">
                                {user.id}
                              </td>
                              <td class="h-12 px-6 text-sm transition duration-300 border-t border-l first:border-l-0 border-slate-200 stroke-slate-500 text-slate-500 ">
                                {user.name}
                              </td>
                              <td class="h-12 px-6 text-sm transition duration-300 border-t border-l first:border-l-0 border-slate-200 stroke-slate-500 text-slate-500 ">
                                {user.email}
                              </td>

                              <td class="h-12 px-6 text-sm transition duration-300 border-t border-l first:border-l-0 border-slate-200 stroke-slate-500 text-slate-500 ">
                                <div className="flex justify-between align-middle gap-4">
                                  <button
                                    onClick={() => {
                                      setEditUser([
                                        user.id,
                                        user.name,
                                        user.email,
                                      ]);
                                      setShowEditModal(true);
                                    }}
                                    class="inline-flex items-center justify-center h-10 gap-2 px-5 text-sm font-medium tracking-wide text-white transition duration-300 rounded focus-visible:outline-none whitespace-nowrap bg-indigo-500 hover:bg-indigo-600 focus:bg-indigo-700 disabled:cursor-not-allowed disabled:border-indigo-300 disabled:bg-indigo-300 disabled:shadow-none"
                                  >
                                    <span>Edytuj</span>
                                  </button>
                                  <button
                                    onClick={() => {
                                      handleDeleteUser(user.id);
                                    }}
                                    class="inline-flex items-center justify-center h-8 gap-2 px-4 text-xs font-medium tracking-wide text-white transition duration-300 rounded focus-visible:outline-none whitespace-nowrap bg-red-500 hover:bg-red-600 focus:bg-red-700 disabled:cursor-not-allowed disabled:border-red-300 disabled:bg-red-300 disabled:shadow-none"
                                  >
                                    <span>usuń</span>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div className="min-w-[1000px] w-full"></div>
                </>
              )}
            </>
          ) : (
            <div className="text-5xl text-gray-300 font-bold text-center p-8">
              Ładowanie danych
            </div>
          )}
        </div>
      </div>
      {showAddModal && (
        <UserAddModal
          setShowAddModal={setShowAddModal}
          handleStoreNewUser={handleStoreNewUser}
        />
      )}
      {showEditModal && (
        <UserEditModal
          setShowEditModal={setShowEditModal}
          handleStoreEditUser={handleStoreEditUser}
          editUser={editUser}
        />
      )}
    </>
  );
};

export default Users;
