import React from "react";

import HomeCard from "./HomeCard";
import { IoMdAddCircleOutline } from "react-icons/io";
import { VscFiles } from "react-icons/vsc";

import { NavLink } from "react-router-dom";
import axios from "../axios";

const Home = ({ setPage}) => {
  return (
    <div className="mb-auto h-10 flex-grow">
      <div className="text-center text-6xl font-bold pb-24">
        {" "}
        3D Smart Generator Offert{" "}
      </div>

      <div className="flex items-center justify-center gap-6">
        <NavLink to="/history">
          <HomeCard
            header={"Historia Ofert"}
            icon={<VscFiles className="mx-auto" />}
            target={"history"}
            setPage={setPage}
            text={
              "Kliknij by sprawdzić ofery wcześniej dodane przez Ciebie jak i Twój zespół. Edytuj, duplikuj, ukrywaj, usuwaj i eksportuj oferty."
            }
     
          />
        </NavLink>
        <NavLink to="/add">
        <HomeCard
          header={"Nowa Oferta"}
          icon={<IoMdAddCircleOutline className="mx-auto text-emerald-500" />}
          target={"add"}
          setPage={setPage}
          text={
            "Kliknij by dodać nową ofertę z przygotowanego szablonu. Wykonaj lekkie modyfikacje wyglądu ofery. Zapisz ofery w bazie danych i udostępnij je swojemu zespołowi."
          }
        />
        </NavLink>
      </div>

      {/* <!-- End Card with icon --> */}
    </div>
  );
};

export default Home;
