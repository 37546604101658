import { createBrowserRouter } from 'react-router-dom';
import Login from './pages/Login';
import About from './pages/About';
import Profile from './pages/Profile';
import ProtectedLayout from './components/ProtectedLayout';
import GuestLayout from './components/GuestLayout';
import App from "./App";
import Add from './components/Add';
import Edit from './components/Edit';
import History from './components/History';
import Users from './components/Users';

const router = createBrowserRouter([
	{
		path: '/',
		element: <GuestLayout />,
		children: [
			{
				path: '/',
				element: <Login />,
			},
			// {
			// 	path: '/register',
			// 	element: <Register />,
			// },
		],
	},
	{
		path: '/',
		element: <ProtectedLayout />,
		children: [
			{
				path: '/',
				element: <App />,
			},
			{
				path: '/admin/users',
				element: <Users />,
			},
			
			{
				path: '/add',
				element: <Add />,
			},
			{
				path: '/edit',
				element: <Edit />,
			},
			{
				path: '/history',
				element: <History />,
			},
			{
				path: '/*',
				element: <App />,
			},
			
		],
	},
]);

export default router;