import React, { useEffect, useState } from "react";

const AddVisualization = ({
  template,
  templates,
  activeTemplate,
  images,
  data,
}) => {
  const documentWidth = 730;
  const {
    line1Css,
    line2Css,
    line3Css,
    productLine1Css,
    productLine2Css,
    productLine3Css,
    materialsCss,
    priceHeaderCss,
    priceCss,
    rpriceHeaderCss,
    rpriceCss,
    productCss,
  } = data.css;
  const {
    line1,
    line2,
    line3,
    productLine1,
    productLine2,
    productLine3,
    materials,
    price,
    priceHead,
    priceText,
    priceVat,
    rprice,
    rpriceHead,
    rpriceText,
    rpriceVat,
  } = data.text;

  
  return (
    <>
      {false ? (
        <></>
      ) : (
        <>
          <div
            id="visualization"
            style={{
              background: "rgba(255,255,255,0)",
              // border: "1px solid red",
              height: "fit-content",
            }}
          >
            <div
              style={{
                width: "730px",
                minHeight: "450px",
                backgroundColor: "#030304",
                backgroundImage:
                  "url(" + templates[activeTemplate].backgroundImageUrl + ")",
                backgroundRepeat: "no-repeat",
                paddingTop: "50px",
                paddingBottom: "10px",
                backgroundSize: "cover",
              }}
            >
              <div
                id="header"
                style={{
                  width: "560px",
                  margin: "0 auto",
                  display: "flex",
                }}
              >
                <img
                  src={images.forbes2022}
                  alt="Nagroda: Diament Miesięcznika Forbes 2022"
                  style={{
                    width: "72px",
                    height: "56px",
                  }}
                />
                <img
                  src={images.logo}
                  alt="3D Smart Logo"
                  style={{ margin: "0 auto" }}
                />
                <img
                  src={images.forbes2023}
                  alt="Nagroda: Diament Miesięcznika Forbes 2023"
                  style={{
                    width: "72px",
                    height: "56px",
                  }}
                />
              </div>

              <div
                style={{
                  width: line1Css.width + "px",
                  margin: "0 auto",
                  textAlign: "center",
                  color: "white",
                  fontWeight: 600,
                  fontSize: line1Css.fontSize + "px",
                  lineHeight: line1Css.lineHeight + "px",
                  fontFamily:
                    "'Euclid Square', 'Century Gothic', Arial, sans-serif",
                  paddingTop: line1Css.paddingTop + "px",
                  paddingBottom: line1Css.paddingBottom + "px",
                }}
              >
                {line1}
                <div
                  style={{
                    width: "70%",
                    height: "3px",
                    backgroundColor: "#33B9FF",
                    margin: "15px auto",
                  }}
                ></div>
              </div>

              <div
                style={{
                  width: line2Css.width + "px",
                  margin: "0 auto",
                  textAlign: "center",
                  color: "white",
                  fontWeight: 100,
                  fontSize: line2Css.fontSize + "px",
                  lineHeight: line2Css.lineHeight + "px",
                  fontFamily:
                    "'Euclid Square', 'Century Gothic', Arial, sans-serif",
                  paddingTop: line2Css.paddingTop + "px",
                  paddingBottom: line2Css.paddingBottom + "px",
                }}
              >
                {line2}
              </div>

              <div
                style={{
                  margin: "10px auto",
                  width: template.productWidth + "px",
                  height: template.productHeight + "px",
                  backgroundImage:
                    "url(" + templates[activeTemplate].productImageUrl + ")",
                  backgroundPosition: "center center",
                  backgroundSize: "cover",
                }}
              >
                <div
                  style={{
                    width: productCss.textWidth + "px",
                  }}
                >
                  <div
                    style={{
                      // border: "1px solid red",
                      paddingTop: "45px",
                      display: "flex",
                      width: "100%",
                      flexFlow: "column",
                    }}
                  >
                    <div
                      style={{
                        width: productCss.leftColWidth + "%",
                      }}
                    ></div>
                    <div
                      style={{
                        width: 100 - productCss.leftColWidth + "%",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                        }}
                      >
                        <div
                          style={{
                            color: "#fff",
                            fontSize: productLine1Css.fontSize + "px",
                            lineHeight: productLine1Css.lineHeight + "px",
                            fontFamily:
                              "'Euclid Square', 'Century Gothic', Arial, sans-serif",
                            fontWeight: 100,
                            textAlign: "center",
                            height: productLine1Css.lineHeight + "px",
                          }}
                        >
                          {productLine1}
                        </div>

                        <div
                          style={{
                            color: "#fff",
                            fontSize: productLine2Css.fontSize + "px",
                            lineHeight: productLine2Css.lineHeight + "px",
                            fontFamily:
                              "'Euclid Square', 'Century Gothic', Arial, sans-serif",
                            fontWeight: 600,
                            textAlign: "center",
                            height: productLine2Css.lineHeight + "px",
                          }}
                        >
                          {productLine2}
                        </div>

                        <div
                          style={{
                            color: "#fff",
                            fontSize: productLine3Css.fontSize + "px",
                            lineHeight: productLine3Css.lineHeight + "px",
                            fontFamily:
                              "'Euclid Square', 'Century Gothic', Arial, sans-serif",
                            fontWeight: 100,
                            textAlign: "center",
                            height: productLine3Css.lineHeight + "px",
                          }}
                        >
                          {productLine3}
                        </div>
                      </div>
                    </div>
                    <td></td>
                  </div>
                </div>
              </div>

              <div
                style={{
                  width: line3Css.width + "px",
                  margin: "0 auto",
                  textAlign: "center",
                  color: "white",
                  fontWeight: 100,
                  fontSize: line3Css.fontSize + "px",
                  lineHeight: line3Css.lineHeight + "px",
                  fontFamily:
                    "'Euclid Square', 'Century Gothic', Arial, sans-serif",
                  paddingTop: line3Css.paddingTop + "px",
                  paddingBottom: line3Css.paddingBottom + "px",
                }}
                dangerouslySetInnerHTML={{ __html: line3 }}
              ></div>

              <div
                style={{
                  width: materialsCss.width + "px",
                  margin: "0 auto",
                  textAlign: "center",
                  color: "white",
                  fontWeight: 600,
                  fontSize: materialsCss.fontSize + "px",
                  lineHeight: materialsCss.lineHeight + "px",
                  fontFamily:
                    "'Euclid Square', 'Century Gothic', Arial, sans-serif",
                  paddingTop: materialsCss.paddingTop + "px",
                  paddingBottom: materialsCss.paddingBottom + "px",
                }}
              >
                ZESTAWIENIE MATERIAŁÓW
              </div>
              <div
                style={{
                  width: "36%",
                  height: "15px",
                  borderBottom: "3px solid #33B9FF",

                  margin: "0px auto",
                }}
              ></div>
              <div
                style={{
                  width: materialsCss.width + "px",
                  display: "flex",
                  flexDirection: "column",
                  justifyItems: "center",
                  alignItems: "center",
                  justifyContent: "center",
                  margin: "0 auto",
                }}
              >
                {materials.map((material) => {
                  return (
                    <div
                      style={{
                        background: "rgba(255,255,255, .1)",
                        borderRadius: "15px",
                        width: "100%",
                        marginTop: "5px",
                        color: "white",
                        fontWeight: 100,
                        fontSize: "13px",
                        lineHeight: "20px",
                        fontFamily:
                          "'Euclid Square', 'Century Gothic', Arial, sans-serif",
                        paddingTop: "5px",
                        paddingBottom: "5px",
                        width: "100%",
                        textAlign: "center",
                      }}
                    >
                      {material}
                    </div>
                  );
                })}
              </div>

              <div
                style={{
                  width: priceCss.width + "px",
                  margin: "0 auto",
                  marginTop: "30px",
                  padding: "10px",

                  backgroundColor: "#33B9FF",
                  borderRadius: "30px",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    color: "white",
                    fontWeight: 100,
                    fontSize: priceHeaderCss.fontSize + "px",
                    lineHeight: priceHeaderCss.lineHeight + "px",
                    fontFamily:
                      "'Euclid Square', 'Century Gothic', Arial, sans-serif",
                  }}
                >
                  {priceHead}
                </div>

                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    color: "white",
                    fontWeight: 600,
                    fontSize: priceCss.fontSize + "px",
                    lineHeight: priceCss.lineHeight + "px",
                    fontFamily:
                      "'Euclid Square', 'Century Gothic', Arial, sans-serif",
                  }}
                >
                  {price} {priceText}{" "}
                  <span style={{ fontWeight: "100" }}> {priceVat} </span>
                </div>
              </div>
              <div
                style={{
                  width: rpriceCss.width + "px",
                  margin: "0 auto",
                  marginTop: "10px",
                  marginBottom: "10px",
                  padding: "10px",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    color: "white",
                    fontWeight: 100,
                    fontSize: rpriceHeaderCss.fontSize + "px",
                    lineHeight: rpriceHeaderCss.lineHeight + "px",
                    fontFamily:
                      "'Euclid Square', 'Century Gothic', Arial, sans-serif",
                  }}
                >
                  {rpriceHead}
                </div>

                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    color: "white",
                    fontWeight: 600,
                    fontSize: rpriceCss.fontSize + "px",
                    lineHeight: rpriceCss.lineHeight + "px",
                    fontFamily:
                      "'Euclid Square', 'Century Gothic', Arial, sans-serif",
                  }}
                >
                  {rprice} {rpriceText}{" "}
                  <span style={{ fontWeight: "100" }}> {rpriceVat} </span>
                </div>
              </div>
            </div>
            <div
              style={{
                width: "730px",
                height: "75px",
                backgroundImage: "url(" + images.footer + ")",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
            ></div>
          </div>
        </>
      )}
    </>
  );
};

export default AddVisualization;
