import React, { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { NavLink } from "react-router-dom";
import axios from "../axios";
import { useAuth } from "../context/AuthContext";
import { useState } from "react";

import Header from "./Header";
import Footer from "./Footer";

export default function DefaultLayout() {
  const { user, setUser } = useAuth();
  const [page, setPage] = useState("home");
  const [hide, setHide] = useState(false);

  // check if user is logged in or not from server
  useEffect(() => {
    (async () => {
      try {
        const resp = await axios.get("/user");
        if (resp.status === 200) {
          setUser(resp.data.data);
        }
      } catch (error) {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          window.location.href = "/";
        }
      }
    })();
  }, []);

  // if user is not logged in, redirect to login page
  if (!user) {
    return <Navigate to="/" />;
  }

  // logout user
  const handleLogout = async () => {
    try {
      const resp = await axios.post("/logout");
      if (resp.status === 200) {
        localStorage.removeItem("user");
        window.location.href = "/";
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="App flex flex-col h-screen justify-between">
        {!hide && <Header page={page} setPage={setPage} />}
        <div className="container flex-grow mx-auto max-w-full  lg:max-w-5xl xl:max-w-7xl 2xl:max-w-[96rem] pt-12 pb-12">
          {/* {page === "home" && <Home page={page} setPage={setPage} />}
        {page === "add" && <Add page={page} setPage={setPage} hide={hide} setHide={setHide} />}
        {page === "history" && <History page={page} setPage={setPage}  />} */}
          <Outlet />
        </div>
        {!hide && <Footer />}
      </div>
      
    </>
  );
}
