import React, { useEffect, useState, useRef } from "react";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import AddControlls from "./AddControlls";
import AddVisualization from "./AddVisualization";
import { useAuth } from "../context/AuthContext";
import axios from "../axios";
import { FiSave } from "react-icons/fi";

const Add = ({ hide, setHide }) => {
  console.log("RENDER ADD")
  const mode = "add";
  const { csrfToken } = useAuth();
  const [activeTemplate, setActiveTemplate] = useState(0);
  const [ofertaId, setOfertaId] = useState(-1);
  const [html, setHtml] = useState("");

  const getHtml = () => {
    const data = document.getElementById("visualization").innerHTML;
    setHtml(data);
  };

  const templates = [
    {
      backgroundImageUrl: window.location.origin + "/bg1.png",
      productImageUrl: window.location.origin + "/pompa01.png",
      productWidth: 545,
      productHeight: 130,
      text: {
        line1:
          "Bardzo dziękujemy za zainteresowanie Naszą ofertą i złożenie zapytania!",
        line2:
          "Według przekazanych informacji najlepszym rozwiązaniem dla Państwa jeśli chodzi o pompę ciepła będzie: ",
        line3:
          "Urządzenie wyróżnia się przede wszystkim tym, że utrzymuje wysoką sprawność nie zależenie od warunków <span style='font-weight: 600'> nawet przy temperaturze -20°C.</span>",
        productLine1: "POMPA CIEPŁA",
        productLine2: "PANASONIC T-CAP 9kW 1F",
        productLine3: "SPLIT",
        materials: [
          "Bojler 200l Ferroli.",
          "Bufor 120L.",
          "Zawór 3 drogowy, separator powietrza i zanieczyszczeń.",
          "Rurki gazowe, materiały użyte do podłączenia z istniejącą instalacją CO i CWU.",
          "Montaż i uruchomienie instalacji.",
        ],
        priceHead: "CENA PO DOTACJI",
        price: "52.000",
        priceText: "zł brutto",
        priceVat: "(8% VAT)",
        rpriceHead: "CENA REGULARNA",
        rprice: "52.000",
        rpriceText: "zł brutto",
        rpriceVat: "(8% VAT)",
      },
      css: {
        line1Css: {
          width: 300,
          fontSize: 16,
          lineHeight: 20,
          paddingTop: 32,
          paddingBottom: 20,
        },
        line2Css: {
          width: 385,
          fontSize: 13,
          lineHeight: 16,
          paddingTop: 0,
          paddingBottom: 0,
        },
        line3Css: {
          width: 435,
          fontSize: 13,
          lineHeight: 16,
          paddingTop: 32,
          paddingBottom: 32,
        },
        productLine1Css: {
          fontSize: 13,
          lineHeight: 16,
        },
        productLine2Css: {
          fontSize: 22,
          lineHeight: 28,
        },
        productLine3Css: {
          fontSize: 13,
          lineHeight: 16,
        },
        productCss: {
          textWidth: 370,
          textLeftColWidth: "1",
          marginTop: 30,
        },
        materialsCss: {
          width: 545,
          fontSize: 15,
          lineHeight: 19,
          paddingTop: 0,
          paddingBottom: 0,
        },
        priceHeaderCss: {
          fontSize: 13,
          lineHeight: 16,
        },
        priceCss: {
          fontSize: 22,
          lineHeight: 28,
          width: 373,
        },
        rpriceHeaderCss: {
          fontSize: 13,
          lineHeight: 16,
        },
        rpriceCss: {
          fontSize: 15,
          lineHeight: 19,
          width: 373,
        },
      },
    },
    {
      backgroundImageUrl: window.location.origin + "/bg2.png",
      productImageUrl: window.location.origin + "/panel01.png",
      productWidth: 410,
      productHeight: 124,
      text: {
        line1:
          "Bardzo dziękujemy za zainteresowanie Naszą ofertą i złożenie zapytania!",
        line2:
          "WWedług przekazanych informacji najlepszym rozwiązaniem dla Pana jeśli chodzi o instalację fotowoltaiczną będzie: ",
        line3:
          "Nowoczesna technologia modułów SHINGLED to idealne rozwiązanie dla tych, którzy cenią sobie elegancję i prostotę w jednym, jednocześnie zachowując praktycznie nienaruszony wygląd dachu na budynku.",
        productLine1: "PANELE FOTOWOLTAICZNE",
        productLine2: "SHINGLED",
        productLine3: "",
        materials: [
          "24 panele 3DSmart 415W Full Black.",
          "Falownik Sofar Solar HYD10KTL.",
          "Zabezpieczenia AC i DC + PPOŻ.",
          "Konstrukcje aluminiowe, przewody.",
          "2x Sofar Solar BTS-5K Bateria 5kWh.",
          "Podstawa + jednostka sterująca magazynu energii.",
          "Montaż i uruchomienie zestawu.",
        ],
        priceHead: "CENA PO DOTACJI",
        price: "52.000",
        priceText: "zł brutto",
        priceVat: "(8% VAT)",
        rpriceHead: "CENA REGULARNA",
        rprice: "52.000",
        rpriceText: "zł brutto",
        rpriceVat: "(8% VAT)",
      },
      css: {
        line1Css: {
          width: 300,
          fontSize: 16,
          lineHeight: 20,
          paddingTop: 32,
          paddingBottom: 20,
        },
        line2Css: {
          width: 385,
          fontSize: 13,
          lineHeight: 16,
          paddingTop: 0,
          paddingBottom: 10,
        },
        line3Css: {
          width: 435,
          fontSize: 13,
          lineHeight: 16,
          paddingTop: 32,
          paddingBottom: 32,
        },
        productLine1Css: {
          fontSize: 13,
          lineHeight: 16,
        },
        productLine2Css: {
          fontSize: 22,
          lineHeight: 28,
        },
        productLine3Css: {
          fontSize: 13,
          lineHeight: 16,
        },
        productCss: {
          textWidth: 320,
          textLeftColWidth: "1",
          marginTop: 50,
        },
        materialsCss: {
          width: 545,
          fontSize: 15,
          lineHeight: 19,
          paddingTop: 0,
          paddingBottom: 0,
        },
        priceHeaderCss: {
          fontSize: 13,
          lineHeight: 16,
        },
        priceCss: {
          fontSize: 22,
          lineHeight: 28,
          width: 373,
        },
        rpriceHeaderCss: {
          fontSize: 13,
          lineHeight: 16,
        },
        rpriceCss: {
          fontSize: 15,
          lineHeight: 19,
          width: 373,
        },
      },
    },
  ];
  const getCurrentFormattedDate = () => {
    const currentDate = new Date();

    // Get the date components
    const day = currentDate.getDate().toString().padStart(2, "0");
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    const year = currentDate.getFullYear();

    // Get the time components
    const hours = currentDate.getHours().toString().padStart(2, "0");
    const minutes = currentDate.getMinutes().toString().padStart(2, "0");

    // Construct the formatted date string
    const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}`;

    return formattedDate;
  };
  const images = {
    logo: window.location.origin + "/3dsmartlogo.png",
    forbes2022: window.location.origin + "/forbes2022.png",
    forbes2023: window.location.origin + "/forbes2023.png",
    footer: window.location.origin + "/footer.png",
    material: window.location.origin + "/materialbg.png",
    price: window.location.origin + "/pricebg.png",
  };

  const [nameInput, setNameInput] = useState(
    "Oferta dla ... z dnia " + getCurrentFormattedDate()
  );
  const [saveing, setSaveing] = useState(false);
  const [typeValue, setTypeValue] = useState(0);

  const [data, setData] = useState({
    text: {
      line1: templates[activeTemplate].text.line1,
      line2: templates[activeTemplate].text.line2,
      line3: templates[activeTemplate].text.line3,
      productLine1: templates[activeTemplate].text.productLine1,
      productLine2: templates[activeTemplate].text.productLine2,
      productLine3: templates[activeTemplate].text.productLine3,
      materials: templates[activeTemplate].text.materials,
      price: templates[activeTemplate].text.price,
      priceHead: templates[activeTemplate].text.priceHead,
      priceText: templates[activeTemplate].text.priceText,
      priceVat: templates[activeTemplate].text.priceVat,
      rprice: templates[activeTemplate].text.rprice,
      rpriceHead: templates[activeTemplate].text.rpriceHead,
      rpriceText: templates[activeTemplate].text.rpriceText,
      rpriceVat: templates[activeTemplate].text.rpriceVat,
    },
    css: {
      line1Css: templates[activeTemplate].css.line1Css,
      line2Css: templates[activeTemplate].css.line2Css,
      line3Css: templates[activeTemplate].css.line3Css,
      productLine1Css: templates[activeTemplate].css.productLine1Css,
      productLine2Css: templates[activeTemplate].css.productLine2Css,
      productLine3Css: templates[activeTemplate].css.productLine3Css,
      productCss: templates[activeTemplate].css.productCss,
      materialsCss: templates[activeTemplate].css.materialsCss,
      priceHeaderCss: templates[activeTemplate].css.priceHeaderCss,
      priceCss: templates[activeTemplate].css.priceCss,
      rpriceHeaderCss: templates[activeTemplate].css.rpriceHeaderCss,
      rpriceCss: templates[activeTemplate].css.rpriceCss,
    },
  });

  const updateData = (type, parameter, value) => {
    let newData = {...data};
    newData[type][parameter] = value;
    setData(newData);
  }


  const handleActiveTemplateChange = (newTemplate) => {
    setActiveTemplate(newTemplate);
    setData({
      text: {
        line1: templates[newTemplate].text.line1,
        line2: templates[newTemplate].text.line2,
        line3: templates[newTemplate].text.line3,
        productLine1: templates[newTemplate].text.productLine1,
        productLine2: templates[newTemplate].text.productLine2,
        productLine3: templates[newTemplate].text.productLine3,
        materials: templates[newTemplate].text.materials,
        price: templates[newTemplate].text.price,
        priceHead: templates[newTemplate].text.priceHead,
        priceText: templates[newTemplate].text.priceText,
        priceVat: templates[newTemplate].text.priceVat,
        rprice: templates[newTemplate].text.rprice,
        rpriceHead: templates[newTemplate].text.rpriceHead,
        rpriceText: templates[newTemplate].text.rpriceText,
        rpriceVat: templates[newTemplate].text.rpriceVat,
      },
      css: {
        line1Css: templates[newTemplate].css.line1Css,
        line2Css: templates[newTemplate].css.line2Css,
        line3Css: templates[newTemplate].css.line3Css,
        productLine1Css: templates[newTemplate].css.productLine1Css,
        productLine2Css: templates[newTemplate].css.productLine2Css,
        productLine3Css: templates[newTemplate].css.productLine3Css,
        productCss: templates[newTemplate].css.productCss,
        materialsCss: templates[newTemplate].css.materialsCss,
        priceHeaderCss: templates[newTemplate].css.priceHeaderCss,
        priceCss: templates[newTemplate].css.priceCss,
        rpriceHeaderCss: templates[newTemplate].css.rpriceHeaderCss,
        rpriceCss: templates[newTemplate].css.rpriceCss,
      },
    });
  }

  const printRef = useRef();

  const handleDownloadImage = async () => {
    const element = printRef.current;
    const canvas = await html2canvas(document.getElementById("visualization"), {
      useCORS: true,
      allowTaint: false,
      letterRendering: 1,
    });

    const data = canvas.toDataURL("image/png");
    const link = document.createElement("a");

    if (typeof link.download === "string") {
      link.href = data;
      link.download = "oferta.png";

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(data);
    }
  };
  const handleDownloadPdf = async (full) => {
    console.log("GO PDF!");
    const canvas = await html2canvas(document.getElementById("visualization"), {
      logging: true,
      useCORS: true,
      allowTaint: true,
      letterRendering: 1,
      dpi: 300,
    });
    const data = canvas.toDataURL("image/png");

    let pdf;
    if (full) {
      pdf = new jsPDF("p", "px", [canvas.width, canvas.height]);
    } else {
      pdf = new jsPDF("p", "mm", "a4");
    }
    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
    pdf.setFillColor("#030304");
    pdf.rect(
      0,
      0,
      pdf.internal.pageSize.getWidth(),
      pdf.internal.pageSize.getHeight(),
      "F"
    );
    pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save("print.pdf");
  };

  const handleHtmlPrint = () => {
    setHide(true);
    // document
    //   .getElementById("htmlprint")
    //   .innerText = document.getElementById("visualization").innerHTML;
  };

  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);

  const handleSaveButtonClick = () => {
    console.log("handleSaveButtonClick");
    ofertaId === -1 ? handleSaveData() : handleUpdateData();
  };

  const handleUpdateData = async () => {
    console.log("handleUpdateData");
    setSaveing(true);
    const updatedata = {
      text: data.text,
      css: data.css,
      template: activeTemplate,
    };

    const body = {
      data: JSON.stringify(updatedata),
      name: nameInput,
      status: typeValue,
      id: ofertaId,
    };
    await csrfToken();
    try {
      const resp = await axios.post("/oferta/update", body);

      setSaveing(false);
      setShowAlert(true);
      setOfertaId(resp.data.id);
      setTimeout(() => {
        setShowAlert(false);
      }, 5000);
    } catch (error) {
      console.log(error);
      setSaveing(false);
      setShowAlert(true);
      setAlertType(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 5000);
    }
  };
  const handleSaveData = async () => {
    console.log("handleSaveData");
    setSaveing(true);
    const savedata = {
      text: data.text,
      css: data.css,
      template: activeTemplate,
    };

    const body = {
      data: JSON.stringify(savedata),
      name: nameInput,
      status: typeValue,
    };
    await csrfToken();
    try {
      const resp = await axios.post("/oferta/create", body);
      console.log("ssssssuuuuccceeeesss");
      setSaveing(false);
      setShowAlert(true);
      setAlertType(false);
      setOfertaId(resp.data.id);
      setTimeout(() => {
        setShowAlert(false);
      }, 5000);
      console.log(resp.data.id);
    } catch (error) {
      console.log("eeeerrrooooorrrr");
      console.log(error);
      setSaveing(false);
      setShowAlert(true);
      setAlertType(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 5000);
    }
  };
  useEffect(() => {
    const accordionItems = document.querySelectorAll(".controlls details");

    accordionItems.forEach((item) => {
      item.addEventListener("click", () => {
        // When a <details> element is opened, close all others
        accordionItems.forEach((otherItem) => {
          if (otherItem !== item) {
            otherItem.removeAttribute("open");
          }
        });
      });
    });
  }, []);

  return (
    <>
      {!hide && (
        <div className="flex  gap-8 min-h-screen">
          <div className="controlls flex-grow max-h-[1000px] overflow-y-scroll">
            <AddControlls
              templates={templates}
              activeTemplate={activeTemplate}
              data={data}
              setData={setData}
              updateData={updateData}
              handleActiveTemplateChange={handleActiveTemplateChange}
              mode={mode}
            />
            <div className=" mt-4 overflow-hidden bg-white rounded shadow-md text-slate-500 shadow-slate-200">
              <details className="p-4 group">
                <summary className="[&::-webkit-details-marker]:hidden relative pr-8 font-medium list-none cursor-pointer text-slate-700 focus-visible:outline-none transition-colors duration-300 group-hover:text-slate-900 ">
                  Zapisz ofertę
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="absolute right-0 w-4 h-4 transition duration-300 top-1 stroke-slate-700 shrink-0 group-open:rotate-45"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="1.5"
                    aria-labelledby="title-ac01 desc-ac01"
                  >
                    <title id="title-ac01">Open icon</title>
                    <desc id="desc-ac01">
                      icon that represents the state of the summary
                    </desc>
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 4v16m8-8H4"
                    />
                  </svg>
                </summary>
                <div className="mt-4 text-slate-500">
                  <div className="relative my-6">
                    <div className="relative my-6">
                      <input
                        id="id-b03"
                        type="text"
                        name="id-b03"
                        placeholder="your name"
                        className="relative w-full h-10 px-4 text-sm placeholder-transparent transition-all border rounded outline-none focus-visible:outline-none peer border-slate-200 text-slate-500 autofill:bg-white invalid:border-pink-500 invalid:text-pink-500 focus:border-emerald-500 focus:outline-none invalid:focus:border-pink-500 disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
                        value={nameInput}
                        onChange={(e) => setNameInput(e.target.value)}
                      />
                      <label
                        for="id-b03"
                        className="cursor-text peer-focus:cursor-default absolute left-2 -top-2 z-[1] px-2 text-xs text-slate-400 transition-all before:absolute before:top-0 before:left-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-placeholder-shown:top-2.5 peer-placeholder-shown:text-sm peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-invalid:text-pink-500 peer-focus:-top-2 peer-focus:text-xs peer-focus:text-emerald-500 peer-invalid:peer-focus:text-pink-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
                      >
                        Opis oferty
                      </label>
                    </div>
                    <div class="relative my-6 md:w-100">
                      <select
                        id="id-07"
                        name="id-07"
                        required
                        defaultValue={typeValue}
                        onChange={(e) => setTypeValue(e.target.value)}
                        class="relative w-full h-12 px-4 transition-all bg-white border-b outline-none appearance-none focus-visible:outline-none peer border-slate-200 text-slate-500 autofill:bg-white focus:border-emerald-500 focus:focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
                      >
                        <option value="0">Publiczna</option>
                        <option value="1">Prywatna</option>
                      </select>
                      <label
                        for="id-07"
                        class="pointer-events-none absolute top-3 left-2 z-[1] px-2 text-base text-slate-400 transition-all before:absolute before:top-0 before:left-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-valid:-top-2 peer-valid:text-xs peer-focus:-top-2 peer-focus:text-xs peer-focus:text-emerald-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
                      >
                        Widoczność oferty
                      </label>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="pointer-events-none absolute top-3.5 right-2 h-5 w-5 fill-slate-400 transition-all peer-focus:fill-emerald-500 peer-disabled:cursor-not-allowed"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-labelledby="title-07 description-07"
                        role="graphics-symbol"
                      >
                        <title id="title-07">Arrow Icon</title>
                        <desc id="description-07">
                          Arrow icon of the select list.
                        </desc>
                        <path
                          fillRule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>

                    {!saveing ? (
                      <div className="relative mt-4 w-100">
                        <div className="flex flex-wrap gap-4">
                          <button
                            onClick={() => handleSaveButtonClick()}
                            class="inline-flex items-center justify-center h-12 gap-2 px-6 text-sm font-medium tracking-wide text-white transition duration-300 rounded focus-visible:outline-none whitespace-nowrap bg-emerald-500 hover:bg-emerald-600 focus:bg-emerald-700 disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none"
                          >
                            <span class="order-2">ZAPISZ</span>
                            <span class="relative only:-mx-6">
                              <FiSave />
                            </span>
                          </button>
                          <button
                            onClick={handleDownloadImage}
                            class="inline-flex items-center justify-center h-12 gap-2 px-6 text-sm font-medium tracking-wide text-white transition duration-300 rounded focus-visible:outline-none whitespace-nowrap bg-cyan-500 hover:bg-cyan-600 focus:bg-cyan-700 disabled:cursor-not-allowed disabled:border-cyan-300 disabled:bg-cyan-300 disabled:shadow-none"
                          >
                            <span>PNG</span>
                          </button>
                          <button
                            onClick={() => {
                              handleDownloadPdf(true);
                            }}
                            class="inline-flex items-center justify-center h-12 gap-2 px-6 text-sm font-medium tracking-wide text-white transition duration-300 rounded focus-visible:outline-none whitespace-nowrap bg-violet-500 hover:bg-violet-600 focus:bg-violet-700 disabled:cursor-not-allowed disabled:border-violet-300 disabled:bg-violet-300 disabled:shadow-none"
                          >
                            <span>PDF FULL PAGE</span>
                          </button>
                          <button
                            onClick={() => {
                              handleDownloadPdf(false);
                            }}
                            class="inline-flex items-center justify-center h-12 gap-2 px-6 text-sm font-medium tracking-wide text-white transition duration-300 rounded focus-visible:outline-none whitespace-nowrap bg-violet-500 hover:bg-violet-600 focus:bg-violet-700 disabled:cursor-not-allowed disabled:border-violet-300 disabled:bg-violet-300 disabled:shadow-none"
                          >
                            <span>PDF</span>
                          </button>
                        </div>
                      </div>
                    ) : (
                      <button class="inline-flex items-center justify-center h-12 gap-2 px-6 text-sm font-medium tracking-wide text-white transition duration-300 rounded focus-visible:outline-none justify-self-center whitespace-nowrap bg-emerald-500 hover:bg-emerald-600 focus:bg-emerald-700 disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
                        <span>Zapisuje dane</span>
                        <span class="relative only:-mx-6">
                          <svg
                            class="w-5 h-5 text-white animate-spin"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            role="graphics-symbol"
                            aria-labelledby="title-05 desc-05"
                          >
                            <title id="title-05">Icon title</title>
                            <desc id="desc-05">
                              A more detailed description of the icon
                            </desc>
                            <circle
                              class="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              stroke-width="4"
                            ></circle>
                            <path
                              class="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                          </svg>
                        </span>
                      </button>
                    )}
                    <div className="mt-4">
                      {showAlert && alertType === false ? (
                        <div
                          class=" ease-in flex items-start w-full gap-4 px-4 py-3 text-sm border rounded border-emerald-100 bg-emerald-50 text-emerald-500"
                          role="alert"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="w-5 h-5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            stroke-width="1.5"
                            role="graphics-symbol"
                            aria-labelledby="title-01 desc-01"
                          >
                            <title id="title-01">Icon title</title>
                            <desc id="desc-01">
                              A more detailed description of the icon
                            </desc>
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                          </svg>
                          <p>Oferta poprawnie zapisana w bazie danych!</p>
                        </div>
                      ) : (
                        <></>
                      )}
                      {showAlert && alertType === true ? (
                        <div
                          class="w-full px-4 py-3 text-sm border rounded border-amber-100 bg-amber-50 text-amber-500"
                          role="alert"
                        >
                          <p>
                            Błąd zapisu danych! Spróbuj ponownie za jakiś czas.
                          </p>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </details>
            </div>
          </div>

          <AddVisualization
            ref={printRef}
            template={templates[activeTemplate]}
            templates={templates}
            activeTemplate={activeTemplate}
            images={images}
            data={data}
          />
        </div>
      )}

      {html !== "" && (
        <div
          class="fixed top-0 left-0 z-20 flex items-center justify-center w-screen h-screen bg-slate-300/20 backdrop-blur-sm"
          aria-labelledby="header-3a content-3a"
          aria-modal="true"
          tabindex="-1"
          role="dialog"
        >
          <div
            class="flex max-h-[90vh] w-11/12 max-w-xl flex-col gap-6 overflow-hidden rounded bg-white p-6 text-slate-500 shadow-xl shadow-slate-700/10"
            id="modal"
            role="document"
          >
            <header id="header-3a" class="flex items-center gap-4">
              <h3 class="flex-1 text-xl font-medium text-slate-700">
                Oferta w kodzie HTML
              </h3>
              <button
                onClick={() => {
                  navigator.clipboard.writeText(html);
                }}
                class="inline-flex items-center justify-center h-8 gap-2 px-4 text-xs font-medium tracking-wide text-white transition duration-300 rounded focus-visible:outline-none whitespace-nowrap bg-emerald-500 hover:bg-emerald-600 focus:bg-emerald-700 disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none"
              >
                <span>Kopiuj</span>
              </button>
              <button
                onClick={() => {
                  setHtml("");
                }}
                class="inline-flex items-center justify-center h-10 gap-2 px-5 text-sm font-medium tracking-wide transition duration-300 rounded-full focus-visible:outline-none justify-self-center whitespace-nowrap text-emerald-500 hover:bg-emerald-100 hover:text-emerald-600 focus:bg-emerald-200 focus:text-emerald-700 disabled:cursor-not-allowed disabled:text-emerald-300 disabled:shadow-none disabled:hover:bg-transparent"
                aria-label="close dialog"
              >
                <span class="relative only:-mx-5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-5 h-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="1.5"
                    role="graphics-symbol"
                    aria-labelledby="title-79 desc-79"
                  >
                    <title id="title-79">Icon title</title>
                    <desc id="desc-79">
                      A more detailed description of the icon
                    </desc>
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </span>
              </button>
            </header>
            <div id="content-3a" class="flex-1 overflow-auto">
              <p>{html}</p>
            </div>
          </div>
        </div>
      )}
      {/* <div id="htmlprint"></div> */}
    </>
  );
};

export default Add;
