import React from "react";
import Logo from "../3dslogoczarne.png";

import { IoMdAddCircleOutline } from "react-icons/io";
import { VscFiles } from "react-icons/vsc";
import { AiOutlineLogout } from "react-icons/ai";
import { FiUsers } from 'react-icons/fi';

import { NavLink } from "react-router-dom";

import axios from "../axios";

const handleLogout = async () => {
  try {
    const resp = await axios.post("/logout");
    if (resp.status === 200) {
      localStorage.removeItem("user");
      window.location.href = "/";
    }
  } catch (error) {
    console.log(error);
  }
};

const Header = ({ page, setPage }) => {
  return (
    <>
      {/* <!-- Component: Basic Navbar --> */}
      <header className="relative z-20 w-full border-b shadow-lg border-b-1 border-slate-200 bg-white/90 shadow-slate-700/5 after:absolute after:top-full after:left-0 after:z-10 after:block after:h-px after:w-full after:bg-slate-200 lg:border-slate-200 lg:backdrop-blur-sm lg:after:hidden">
        <div className="relative mx-auto max-w-full  lg:max-w-5xl xl:max-w-7xl 2xl:max-w-[96rem]">
          <nav
            aria-label="main navigation"
            className="flex h-[5.5rem] items-stretch justify-between font-medium text-slate-700"
            role="navigation"
          >
            {/* <!-- Brand logo --> */}
            <a
              aria-label="3D Smart Logo"
              aria-current="page"
              className="flex items-center gap-2 py-3 text-lg whitespace-nowrap focus:outline-none lg:flex-1"
              href="javascript:void(0)"
            >
              <img src={Logo} alt="3D Smart Logo" className="h-12" />
            </a>
            {/* <!-- Mobile trigger --> */}
            <button
              className="relative self-center order-10 visible block w-10 h-10 opacity-100 lg:hidden"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <div className="absolute w-6 transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
                <span
                  aria-hidden="true"
                  className="absolute block h-0.5 w-9/12 -translate-y-2 transform rounded-full bg-slate-900 transition-all duration-300"
                ></span>
                <span
                  aria-hidden="true"
                  className="absolute block h-0.5 w-6 transform rounded-full bg-slate-900 transition duration-300"
                ></span>
                <span
                  aria-hidden="true"
                  className="absolute block h-0.5 w-1/2 origin-top-left translate-y-2 transform rounded-full bg-slate-900 transition-all duration-300"
                ></span>
              </div>
            </button>
            {/* <!-- Navigation links --> */}
            <ul
              role="menubar"
              aria-label="Select page"
              className="invisible absolute top-0 left-0 z-[-1] ml-auto h-screen w-full justify-center overflow-hidden overflow-y-auto overscroll-contain bg-white/90 px-8 pb-12 pt-28 font-medium opacity-0 transition-[opacity,visibility] duration-300 lg:visible lg:relative lg:top-0 lg:z-0 lg:flex lg:h-full lg:w-auto lg:items-stretch lg:overflow-visible lg:bg-white/0 lg:px-0 lg:py-0 lg:pt-0 lg:opacity-100"
            >
              <li role="none" className="flex items-stretch">
                <NavLink to="/history" className="menu-item">
                  {" "}
                  <span>
                    {" "}
                    <VscFiles /> Historia ofert
                  </span>
                </NavLink>
              </li>
              <li role="none" className="flex items-stretch">
                <NavLink to="/add" className="menu-item menu-item-selected">
                  <span>
                    {" "}
                    <IoMdAddCircleOutline /> Nowa oferta
                  </span>{" "}
                </NavLink>
              </li>
              <li role="none" className="flex items-stretch">
                <NavLink to="/admin/users" className="menu-item">
                  {" "}
                  <span>
                    {" "}
                    <FiUsers /> Użytkownicy
                  </span>
                </NavLink>
              </li>
              <li role="none" className="flex items-stretch">
                <a href="#" className="menu-item" onClick={handleLogout}>
                  <span>
                    {" "}
                    <AiOutlineLogout /> Wyloguj
                  </span>{" "}
                </a>
              </li>
              <li role="none" className="flex items-stretch"></li>
            </ul>
          </nav>
        </div>
      </header>
      {/* <!-- End Basic Navbar--> */}
    </>
  );
};

export default Header;
