import React, { useState, useEffect, useRef } from "react";
import { IoMdAddCircleOutline } from "react-icons/io";
import { MdRemoveCircleOutline } from "react-icons/md";
import { TiMinus, TiPlus } from "react-icons/ti";

import AddController from "./AddController";
import DraggableList from "./DraggableList";

const AddControlls = ({
  templates,
  activeTemplate,
  data,
  setData,
  updateData,
  handleActiveTemplateChange,
  mode,
}) => {
  const [selectedTab, setSelectedTab] = useState(-1);
  const [loaded, setLoaded] = useState(false);
  const [inputNewMaterial, setInputNewMaterial] = useState("");

  const handleInputNewMaterialChange = (val) => {
    setInputNewMaterial(val);
  };

  const handleAddMaterial = () => {
    if (inputNewMaterial !== "") {
      const newMaterials = [...data.text.materials, inputNewMaterial];
      updateData("text", "materials", newMaterials);
      setInputNewMaterial("");
    }
  };

  const handleDeleteMatarial = (key) => {
    let newMaterials = [...data.text.materials];
    newMaterials = newMaterials.filter(function (materia, index) {
      return index !== key;
    });
    updateData("text", "materials", newMaterials);
  };

  const handleCssChange = (type, attr, vector, step) => {
    console.log(type, attr, vector, step);
    let newObject = { ...data };
    vector
      ? (newObject["css"][type][attr] = newObject["css"][type][attr] + step)
      : (newObject["css"][type][attr] = newObject["css"][type][attr] - step);
    console.log(newObject);
    setData(newObject);
  };

  // DRAG AND DROP
  const dragItem = useRef();
  const dragOverItem = useRef();

  const dragStart = (e, position) => {
    dragItem.current = position;
    console.log(e.target.innerHTML);
  };

  const dragEnter = (e, position) => {
    dragOverItem.current = position;
    console.log(e.target.innerHTML);
  };

  const drop = (e) => {
    const copyListItems = [...data.text.materials];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    updateData("text", "materials", copyListItems);
  };

  // DND
  const tabSelectedTab =
    "inline-flex items-center justify-center w-full h-12 gap-2 px-6 -mb-px text-sm font-medium tracking-wide transition duration-300 border-b-2 rounded-t focus-visible:outline-none whitespace-nowrap border-emerald-500 hover:border-emerald-600 focus:border-emerald-700 text-emerald-500 hover:text-emerald-600 focus:text-emerald-700 hover:bg-emerald-50 focus:bg-emerald-50 disabled:cursor-not-allowed disabled:border-slate-500 stroke-emerald-500 hover:stroke-emerald-600 focus:stroke-emerald-700";
  const tabUnselectedTab =
    "inline-flex items-center justify-center w-full h-12 gap-2 px-6 -mb-px text-sm font-medium tracking-wide transition duration-300 border-b-2 border-transparent rounded-t focus-visible:outline-none justify-self-center hover:border-emerald-500 focus:border-emerald-600 whitespace-nowrap text-slate-700 stroke-slate-700 hover:bg-emerald-50 hover:text-emerald-500 focus:stroke-emerald-600 focus:bg-emerald-50 focus:text-emerald-600 hover:stroke-emerald-600 disabled:cursor-not-allowed disabled:text-slate-500";
  const tabSelectedPanel = "px-6 py-4";
  const tabUnselectedPanel = "hidden px-6 py-4";

  return (
    <>
      {!true ? (
        <>loading</>
      ) : (
        <>
          <div className="overflow-hidden bg-white rounded shadow-md text-slate-500 shadow-slate-200">
            <section className="w-full divide-y rounded divide-slate-200 ">
              {mode === "add" ? (
                <>
                  <details className="p-4 group">
                    <summary className="[&::-webkit-details-marker]:hidden relative pr-8 font-medium list-none cursor-pointer text-slate-700 focus-visible:outline-none transition-colors duration-300 group-hover:text-slate-900 ">
                      Szablony
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="absolute right-0 w-4 h-4 transition duration-300 top-1 stroke-slate-700 shrink-0 group-open:rotate-45"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="1.5"
                        aria-labelledby="title-ac01 desc-ac01"
                      >
                        <title id="title-ac01">Open icon</title>
                        <desc id="desc-ac01">
                          icon that represents the state of the summary
                        </desc>
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M12 4v16m8-8H4"
                        />
                      </svg>
                    </summary>
                    <div className="mt-4 text-slate-500">
                      <div className="relative my-6 flex gap-4">
                        <button
                          onClick={() => {
                            handleActiveTemplateChange(0);
                          }}
                          class="inline-flex items-center justify-center h-10 gap-2 px-5 text-sm font-medium tracking-wide text-white transition duration-300 rounded focus-visible:outline-none whitespace-nowrap bg-emerald-500 hover:bg-emerald-600 focus:bg-emerald-700 disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none"
                        >
                          <span>Pompa ciepła</span>
                        </button>
                        <button
                          onClick={() => {
                            handleActiveTemplateChange(1);
                          }}
                          class="inline-flex items-center justify-center h-10 gap-2 px-5 text-sm font-medium tracking-wide text-white transition duration-300 rounded focus-visible:outline-none whitespace-nowrap bg-blue-500 hover:bg-blue-600 focus:bg-blue-700 disabled:cursor-not-allowed disabled:border-blue-300 disabled:bg-blue-300 disabled:shadow-none"
                        >
                          <span>Panele fotowoltaiczne</span>
                        </button>
                      </div>
                    </div>
                  </details>
                </>
              ) : (
                <></>
              )}
            </section>
          </div>
          <div className=" mt-4 overflow-hidden bg-white rounded shadow-md text-slate-500 shadow-slate-200">
            <section className="w-full divide-y rounded divide-slate-200 ">
              {/* ------------------------- */}

              <section class="max-w-full" aria-multiselectable="false">
                <ul
                  class="flex items-center border-b border-slate-200"
                  role="tablist"
                >
                  <li role="presentation">
                    <button
                      className={
                        selectedTab === 1 ? tabSelectedTab : tabUnselectedTab
                      }
                      onClick={() => {
                        if (selectedTab === 1) {
                          setSelectedTab(-1);
                        } else {
                          setSelectedTab(1);
                        }
                      }}
                      id="tab-label-1a"
                      role="tab"
                      aria-setsize="3"
                      aria-posinset="1"
                      tabindex="0"
                      aria-controls="tab-panel-1a"
                      aria-selected="true"
                    >
                      <span>Lines</span>
                    </button>
                  </li>
                  <li role="presentation">
                    <button
                      className={
                        selectedTab === 2 ? tabSelectedTab : tabUnselectedTab
                      }
                      onClick={() => {
                        if (selectedTab === 2) {
                          setSelectedTab(-1);
                        } else {
                          setSelectedTab(2);
                        }
                      }}
                      id="tab-label-1b"
                      role="tab"
                      aria-setsize="3"
                      aria-posinset="1"
                      tabindex="0"
                      aria-controls="tab-panel-1b"
                      aria-selected="true"
                    >
                      <span>Product</span>
                    </button>
                  </li>
                  <li role="presentation">
                    <button
                      className={
                        selectedTab === 3 ? tabSelectedTab : tabUnselectedTab
                      }
                      onClick={() => {
                        if (selectedTab === 3) {
                          setSelectedTab(-1);
                        } else {
                          setSelectedTab(3);
                        }
                      }}
                      id="tab-label-1c"
                      role="tab"
                      aria-setsize="3"
                      aria-posinset="1"
                      tabindex="0"
                      aria-controls="tab-panel-1c"
                      aria-selected="true"
                    >
                      <span>Material</span>
                    </button>
                  </li>
                  <li role="presentation">
                    <button
                      className={
                        selectedTab === 4 ? tabSelectedTab : tabUnselectedTab
                      }
                      onClick={() => {
                        if (selectedTab === 4) {
                          setSelectedTab(-1);
                        } else {
                          setSelectedTab(4);
                        }
                      }}
                      id="tab-label-1d"
                      role="tab"
                      aria-setsize="3"
                      aria-posinset="1"
                      tabindex="0"
                      aria-controls="tab-panel-1d"
                      aria-selected="true"
                    >
                      <span>Price</span>
                    </button>
                  </li>
                  <li role="presentation">
                    <button
                      className={
                        selectedTab === 5 ? tabSelectedTab : tabUnselectedTab
                      }
                      onClick={() => {
                        if (selectedTab === 5) {
                          setSelectedTab(-1);
                        } else {
                          setSelectedTab(5);
                        }
                      }}
                      id="tab-label-1e"
                      role="tab"
                      aria-setsize="3"
                      aria-posinset="1"
                      tabindex="0"
                      aria-controls="tab-panel-1e"
                      aria-selected="true"
                    >
                      <span>Price Regular</span>
                    </button>
                  </li>
                </ul>
                <div>
                  <div
                    className={
                      selectedTab === 1 ? tabSelectedPanel : tabUnselectedPanel
                    }
                    id="tab-panel-1a"
                    aria-hidden="false"
                    role="tabpanel"
                    aria-labelledby="tab-label-1a"
                    tabindex="-1"
                  >
                    <p>
                      <AddController
                        title={"Line 1"}
                        attr={"line1"}
                        inputValue={data.text.line1}
                        updateData={updateData}
                        handleCssChange={handleCssChange}
                        handleCssChangeData={[
                          {
                            title: "Szerokość tekstu",
                            width: "218",
                            prop: "width",
                            step: 5,
                          },
                          {
                            title: "Wielkość czcionki",
                            width: "225",
                            prop: "fontSize",
                            step: 1,
                          },
                          {
                            title: "Wysokość linii",
                            width: "200",
                            prop: "lineHeight",
                            step: 1,
                          },
                          {
                            title: "Margines górny",
                            width: "212",
                            prop: "paddingTop",
                            step: 2,
                          },
                          {
                            title: "Margines dolny",
                            width: "212",
                            prop: "paddingBottom",
                            step: 2,
                          },
                        ]}
                      />
                      <AddController
                        title={"Line 2"}
                        attr={"line2"}
                        inputValue={data.text.line2}
                        updateData={updateData}
                        handleCssChange={handleCssChange}
                        handleCssChangeData={[
                          {
                            title: "Szerokość tekstu",
                            width: "218",
                            prop: "width",
                            step: 5,
                          },
                          {
                            title: "Wielkość czcionki",
                            width: "225",
                            prop: "fontSize",
                            step: 1,
                          },
                          {
                            title: "Wysokość linii",
                            width: "200",
                            prop: "lineHeight",
                            step: 1,
                          },
                          {
                            title: "Margines górny",
                            width: "212",
                            prop: "paddingTop",
                            step: 2,
                          },
                          {
                            title: "Margines dolny",
                            width: "212",
                            prop: "paddingBottom",
                            step: 2,
                          },
                        ]}
                      />
                      <AddController
                        title={"Line 3"}
                        attr={"line3"}
                        inputValue={data.text.line3}
                        updateData={updateData}
                        handleCssChange={handleCssChange}
                        handleCssChangeData={[
                          {
                            title: "Szerokość tekstu",
                            width: "218",
                            prop: "width",
                            step: 5,
                          },
                          {
                            title: "Wielkość czcionki",
                            width: "225",
                            prop: "fontSize",
                            step: 1,
                          },
                          {
                            title: "Wysokość linii",
                            width: "200",
                            prop: "lineHeight",
                            step: 1,
                          },
                          {
                            title: "Margines górny",
                            width: "212",
                            prop: "paddingTop",
                            step: 2,
                          },
                          {
                            title: "Margines dolny",
                            width: "212",
                            prop: "paddingBottom",
                            step: 2,
                          },
                        ]}
                      />
                    </p>
                  </div>
                  <div
                    className={
                      selectedTab === 2 ? tabSelectedPanel : tabUnselectedPanel
                    }
                    id="tab-panel-1b"
                    aria-hidden="true"
                    role="tabpanel"
                    aria-labelledby="tab-label-1b"
                    tabindex="-1"
                  >
                    <p>
                      <AddController
                        title={"Product Line 1"}
                        attr={"productLine1"}
                        inputValue={data.text.productLine1}
                        updateData={updateData}
                        handleCssChange={handleCssChange}
                        handleCssChangeData={[
                          {
                            title: "Wielkość czcionki",
                            width: "225",
                            prop: "fontSize",
                            step: 1,
                          },
                          {
                            title: "Wysokość linii",
                            width: "200",
                            prop: "lineHeight",
                            step: 1,
                          },
                        ]}
                      />
                      <AddController
                        title={"Product Line 2"}
                        attr={"productLine2"}
                        inputValue={data.text.productLine2}
                        updateData={updateData}
                        handleCssChange={handleCssChange}
                        handleCssChangeData={[
                          {
                            title: "Wielkość czcionki",
                            width: "225",
                            prop: "fontSize",
                            step: 1,
                          },
                          {
                            title: "Wysokość linii",
                            width: "200",
                            prop: "lineHeight",
                            step: 1,
                          },
                        ]}
                      />
                      <AddController
                        title={"Product Line 3"}
                        attr={"productLine3"}
                        inputValue={data.text.productLine3}
                        updateData={updateData}
                        handleCssChange={handleCssChange}
                        handleCssChangeData={[
                          {
                            title: "Wielkość czcionki",
                            width: "225",
                            prop: "fontSize",
                            step: 1,
                          },
                          {
                            title: "Wysokość linii",
                            width: "200",
                            prop: "lineHeight",
                            step: 1,
                          },
                        ]}
                      />
                    </p>
                  </div>
                  <div
                    className={
                      selectedTab === 3 ? tabSelectedPanel : tabUnselectedPanel
                    }
                    id="tab-panel-1c"
                    aria-hidden="true"
                    role="tabpanel"
                    aria-labelledby="tab-label-1c"
                    tabindex="-1"
                  >
                    <p>
                      <div className="mt-4 text-slate-500">
                        <div className="relative my-6">
                          <div className="relative my-6 flex justify-between gap-4">
                            <input
                              id="id-b035"
                              type="text"
                              name="id-b035"
                              placeholder="line 3"
                              className="relative grow h-10 px-4 text-sm placeholder-transparent transition-all border rounded outline-none focus-visible:outline-none peer border-slate-200 text-slate-500 autofill:bg-white invalid:border-pink-500 invalid:text-pink-500 focus:border-emerald-500 focus:outline-none invalid:focus:border-pink-500 disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
                              value={inputNewMaterial}
                              onChange={(e) =>
                                handleInputNewMaterialChange(e.target.value)
                              }
                            />
                            <label
                              for="id-b035"
                              className="cursor-text peer-focus:cursor-default absolute left-2 -top-2 z-[1] px-2 text-xs text-slate-400 transition-all before:absolute before:top-0 before:left-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-placeholder-shown:top-2.5 peer-placeholder-shown:text-sm peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-invalid:text-pink-500 peer-focus:-top-2 peer-focus:text-xs peer-focus:text-emerald-500 peer-invalid:peer-focus:text-pink-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
                            >
                              New Material
                            </label>
                            <button
                              onClick={() => {
                                handleAddMaterial();
                              }}
                              className="inline-flex items-center self-center justify-center h-10 gap-2 px-10 text-sm font-medium tracking-wide text-white transition duration-300 rounded focus-visible:outline-none whitespace-nowrap bg-emerald-500 hover:bg-emerald-600 focus:bg-emerald-700 disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none"
                            >
                              <span className="relative only:-mx-5">
                                <span className="sr-only">
                                  Add new material
                                </span>
                                <IoMdAddCircleOutline />
                              </span>
                            </button>
                          </div>
                          {data.text.materials &&
                            data.text.materials.map((item, index) => (
                              <div>
                                <div
                                  className="flex justify-center border rounded cursor-grab"
                                  onDragStart={(e) => dragStart(e, index)}
                                  onDragEnter={(e) => dragEnter(e, index)}
                                  onDragEnd={drop}
                                  key={index}
                                  draggable
                                >
                                  <div
                                    style={{
                                      lineHeight: "20px",
                                    }}
                                    className="grow p-2"
                                  >
                                    {item}
                                  </div>
                                  <button class="inline-flex items-center self-center justify-center h-10 gap-2 px-5 text-sm font-medium tracking-wide text-white transition duration-300 rounded focus-visible:outline-none whitespace-nowrap bg-rose-500 hover:bg-rose-600 focus:bg-rose-700 disabled:cursor-not-allowed disabled:border-rose-300 disabled:bg-rose-300 disabled:shadow-none">
                                    <span class="relative only:-mx-5">
                                      <span class="sr-only">
                                        Button description
                                      </span>
                                      <MdRemoveCircleOutline
                                        onClick={() => {
                                          handleDeleteMatarial(index);
                                        }}
                                      />
                                    </span>
                                  </button>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </p>
                  </div>
                  <div
                    className={
                      selectedTab === 4 ? tabSelectedPanel : tabUnselectedPanel
                    }
                    id="tab-panel-1d"
                    aria-hidden="true"
                    role="tabpanel"
                    aria-labelledby="tab-label-1d"
                    tabindex="-1"
                  >
                    <p>
                      <AddController
                        title={"Price Header"}
                        attr={"priceHead"}
                        inputValue={data.text.priceHead}
                        updateData={updateData}
                        handleCssChange={handleCssChange}
                        handleCssChangeData={[
                          {
                            title: "Wielkość czcionki",
                            width: "225",
                            prop: "fontSize",
                            step: 1,
                          },
                          {
                            title: "Wysokość linii",
                            width: "200",
                            prop: "lineHeight",
                            step: 1,
                          },
                        ]}
                      />
                      <AddController
                       title={"Price"}
                       attr={"price"}
                       inputValue={data.text.price}
                       updateData={updateData}
                       handleCssChange={handleCssChange}
                        handleCssChangeData={[
                          {
                            title: "Szerokość tekstu",
                            width: "225",
                            prop: "width",
                            step: 5,
                          },
                          {
                            title: "Wielkość czcionki",
                            width: "225",
                            prop: "fontSize",
                            step: 1,
                          },
                          {
                            title: "Wysokość linii",
                            width: "200",
                            prop: "lineHeight",
                            step: 1,
                          },
                        ]}
                      />
                      <AddController
                       title={"Price Text"}
                       attr={"priceText"}
                       inputValue={data.text.priceText}
                       updateData={updateData}
                       handleCssChange={handleCssChange}
                        handleCssChangeData={[
                          
                        ]}
                      />

                      <details className="p-4 group">
                        <summary className="[&::-webkit-details-marker]:hidden relative pr-8 font-medium list-none cursor-pointer text-slate-700 focus-visible:outline-none transition-colors duration-300 group-hover:text-slate-900 ">
                          Price VAT
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="absolute right-0 w-4 h-4 transition duration-300 top-1 stroke-slate-700 shrink-0 group-open:rotate-45"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            stroke-width="1.5"
                            aria-labelledby="title-ac01 desc-ac01"
                          >
                            <title id="title-ac01">Open icon</title>
                            <desc id="desc-ac01">
                              icon that represents the state of the summary
                            </desc>
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M12 4v16m8-8H4"
                            />
                          </svg>
                        </summary>
                        <div className="mt-4 text-slate-500">
                          <div className="relative my-6">
                            <div className="relative my-6">
                              <input
                                id="id-b025"
                                type="text"
                                name="id-b025"
                                placeholder="line 3"
                                className="relative w-full h-10 px-4 text-sm placeholder-transparent transition-all border rounded outline-none focus-visible:outline-none peer border-slate-200 text-slate-500 autofill:bg-white invalid:border-pink-500 invalid:text-pink-500 focus:border-emerald-500 focus:outline-none invalid:focus:border-pink-500 disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
                                value={data.text.priceVat}
                                onChange={(e) =>
                                  updateData("text", "priceVat", e.target.value)
                                }
                              />
                              <label
                                for="id-b025"
                                className="cursor-text peer-focus:cursor-default absolute left-2 -top-2 z-[1] px-2 text-xs text-slate-400 transition-all before:absolute before:top-0 before:left-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-placeholder-shown:top-2.5 peer-placeholder-shown:text-sm peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-invalid:text-pink-500 peer-focus:-top-2 peer-focus:text-xs peer-focus:text-emerald-500 peer-invalid:peer-focus:text-pink-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
                              >
                                Price Vat
                              </label>
                            </div>
                          </div>
                        </div>
                      </details>
                    </p>
                  </div>
                  <div
                    className={
                      selectedTab === 5 ? tabSelectedPanel : tabUnselectedPanel
                    }
                    id="tab-panel-1e"
                    aria-hidden="true"
                    role="tabpanel"
                    aria-labelledby="tab-label-1e"
                    tabindex="-1"
                  >
                    <p>
                    <AddController
                        title={"Regular Price Header"}
                        attr={"rpriceHead"}
                        inputValue={data.text.rpriceHead}
                        updateData={updateData}
                        handleCssChange={handleCssChange}
                        handleCssChangeData={[
                          {
                            title: "Wielkość czcionki",
                            width: "225",
                            prop: "fontSize",
                            step: 1,
                          },
                          {
                            title: "Wysokość linii",
                            width: "200",
                            prop: "lineHeight",
                            step: 1,
                          },
                        ]}
                      />
                      <AddController
                       title={"Regular Price"}
                       attr={"rprice"}
                       inputValue={data.text.rprice}
                       updateData={updateData}
                       handleCssChange={handleCssChange}
                        handleCssChangeData={[
                          {
                            title: "Szerokość tekstu",
                            width: "225",
                            prop: "width",
                            step: 5,
                          },
                          {
                            title: "Wielkość czcionki",
                            width: "225",
                            prop: "fontSize",
                            step: 1,
                          },
                          {
                            title: "Wysokość linii",
                            width: "200",
                            prop: "lineHeight",
                            step: 1,
                          },
                        ]}
                      />
                      <AddController
                       title={"Regular Price Text"}
                       attr={"rpriceText"}
                       inputValue={data.text.rpriceText}
                       updateData={updateData}
                       handleCssChange={handleCssChange}
                        handleCssChangeData={[
                          
                        ]}
                      />

                      <details className="p-4 group">
                        <summary className="[&::-webkit-details-marker]:hidden relative pr-8 font-medium list-none cursor-pointer text-slate-700 focus-visible:outline-none transition-colors duration-300 group-hover:text-slate-900 ">
                          Regular Price VAT
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="absolute right-0 w-4 h-4 transition duration-300 top-1 stroke-slate-700 shrink-0 group-open:rotate-45"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            stroke-width="1.5"
                            aria-labelledby="title-ac01 desc-ac01"
                          >
                            <title id="title-ac01">Open icon</title>
                            <desc id="desc-ac01">
                              icon that represents the state of the summary
                            </desc>
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M12 4v16m8-8H4"
                            />
                          </svg>
                        </summary>
                        <div className="mt-4 text-slate-500">
                          <div className="relative my-6">
                            <div className="relative my-6">
                              <input
                                id="id-b025"
                                type="text"
                                name="id-b025"
                                placeholder="line 3"
                                className="relative w-full h-10 px-4 text-sm placeholder-transparent transition-all border rounded outline-none focus-visible:outline-none peer border-slate-200 text-slate-500 autofill:bg-white invalid:border-pink-500 invalid:text-pink-500 focus:border-emerald-500 focus:outline-none invalid:focus:border-pink-500 disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
                                value={data.text.rpriceVat}
                                onChange={(e) =>
                                  updateData(
                                    "text",
                                    "rpriceVat",
                                    e.target.value
                                  )
                                }
                              />
                              <label
                                for="id-b025"
                                className="cursor-text peer-focus:cursor-default absolute left-2 -top-2 z-[1] px-2 text-xs text-slate-400 transition-all before:absolute before:top-0 before:left-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-placeholder-shown:top-2.5 peer-placeholder-shown:text-sm peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-invalid:text-pink-500 peer-focus:-top-2 peer-focus:text-xs peer-focus:text-emerald-500 peer-invalid:peer-focus:text-pink-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
                              >
                                Regular Price Vat
                              </label>
                            </div>
                          </div>
                        </div>
                      </details>
                    </p>
                  </div>
                </div>
              </section>

              {/* ------------------------------- */}
            </section>
          </div>
        </>
      )}
    </>
  );
};

export default AddControlls;
