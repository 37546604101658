import React from "react";
import Volium from "../voliumlogo.png";

const Footer = () => {
  return (
    <div className="relative z-20 w-full border-t shadow-lg border-t-1 border-slate-200 bg-white/90 shadow-slate-700/5 after:absolute after:top-full after:left-0 after:z-10 after:block after:h-px after:w-full after:bg-slate-200 lg:border-slate-200 lg:backdrop-blur-sm lg:after:hidden">
      <div className="h-10 text-sm text-center flex items-center justify-center">
        <div className="block mr-2">Projekt i wykonanie </div>
        <div className="">
          <a href="https://volium.pl">
            <img src={Volium} alt="Volium logo" className="h-3" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
