import React from "react";
import { TiMinus, TiPlus } from "react-icons/ti";

const AddController = ({
  title,
  attr,
  inputValue,
  updateData,
  handleCssChangeData,
  handleCssChange,
}) => {
  return (
    <div>
      <details className="p-4 group">
        <summary className="[&::-webkit-details-marker]:hidden relative py-2 pr-8 font-medium list-none cursor-pointer text-slate-700 focus-visible:outline-none transition-colors duration-300 group-hover:text-slate-900 ">
          {title}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="absolute right-0 w-4 h-4 transition duration-300 top-1 stroke-slate-700 shrink-0 group-open:rotate-45"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="1.5"
            aria-labelledby="title-ac01 desc-ac01"
          >
            <title id="title-ac01">Open icon</title>
            <desc id="desc-ac01">
              icon that represents the state of the summary
            </desc>
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M12 4v16m8-8H4"
            />
          </svg>
        </summary>
        <div className="mt-4 text-slate-500">
          <div className="relative my-6">
            <div className="relative my-6">
              <input
                id="id-b03"
                type="text"
                name="id-b03"
                placeholder=""
                className="relative w-full h-10 px-4 text-sm placeholder-transparent transition-all border rounded outline-none focus-visible:outline-none peer border-slate-200 text-slate-500 autofill:bg-white invalid:border-pink-500 invalid:text-pink-500 focus:border-emerald-500 focus:outline-none invalid:focus:border-pink-500 disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
                value={inputValue}
                onChange={(e) => updateData('text',attr,e.target.value)}
              />
              <label
                for="id-b03"
                className="cursor-text peer-focus:cursor-default absolute left-2 -top-2 z-[1] px-2 text-xs text-slate-400 transition-all before:absolute before:top-0 before:left-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-placeholder-shown:top-2.5 peer-placeholder-shown:text-sm peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-invalid:text-pink-500 peer-focus:-top-2 peer-focus:text-xs peer-focus:text-emerald-500 peer-invalid:peer-focus:text-pink-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
              >
                {title}
              </label>
            </div>
            <div className="w-full flex gap-2 flex-wrap">
              {handleCssChangeData.map((data) => {
                return (
                  <div
                    className={
                      "w-[" +
                      data.width +
                      "px] flex gap-4 rounded-full border-solid border-gray-200 border-2"
                    }
                  >
                    <button
                      //  attr,
                      //  vector,
                      //  step
                      onClick={() => {
                        handleCssChange(
                          attr === "priceHead" ? "priceHeaderCss" : attr === "rpriceHead" ? "rPriceHeaderCss"  : attr + "Css",
                          data.prop,
                          false,
                          data.step
                        );
                      }}
                      class="inline-flex items-center self-center justify-center h-8 gap-2 px-4 text-sm font-sm tracking-wide transition duration-300 rounded-full focus-visible:outline-none justify-self-center whitespace-nowrap bg-rose-50 text-rose-500 hover:bg-rose-100 hover:text-rose-600 focus:bg-rose-200 focus:text-rose-700 disabled:cursor-not-allowed disabled:border-rose-300 disabled:bg-rose-100 disabled:text-rose-400 disabled:shadow-none"
                    >
                      <span class="relative only:-mx-6">
                        <span class="sr-only">Zmniejsz wartość</span>
                        <TiMinus />
                      </span>
                    </button>
                    <div className="pt-3">{data.title}</div>
                    <button
                      onClick={() => {
                        handleCssChange(
                          attr === "priceHead" ? "priceHeaderCss" : attr === "rpriceHead" ? "rpriceHeaderCss"  : attr + "Css",
                          data.prop,
                          true,
                          data.step
                        );
                      }}
                      class="inline-flex items-center self-center justify-center h-8 gap-2 px-4 text-sm font-sm tracking-wide transition duration-300 rounded-full focus-visible:outline-none justify-self-center whitespace-nowrap bg-emerald-50 text-emerald-500 hover:bg-emerald-100 hover:text-emerald-600 focus:bg-emerald-200 focus:text-emerald-700 disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-100 disabled:text-emerald-400 disabled:shadow-none"
                    >
                      <span class="relative only:-mx-6">
                        <span class="sr-only">Zwiększ wartość</span>
                        <TiPlus />
                      </span>
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </details>
    </div>
  );
};

export default AddController;
